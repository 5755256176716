export enum ELocalStorage {
  ACCESS_TOKEN = 'accessToken',
  REFRESH_TOKEN = 'refreshToken',
  EXPIRES_IN = 'expiresIn',
  REFRESH_EXPIRES_IN = 'refreshExpiresIn',
  FIRSTNAME = 'firstName',
  LASTNAME = 'lastName',
  USERNAME = 'username',
  ROLE = 'role',
  PERMISSIONS = 'permissions',
  LANGUAGE = 'language',
  USER_ID = 'user_id',
  USER = 'user',
  DEVICE_ID = 'deviceId',

  // login
  LOGIN_STEP = 'login_step',
  LOGIN_PHONE = 'login_phone',
  LOGIN_VERIFY_CODE = 'login_verify_code',
}
